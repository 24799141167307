import * as React from "react"
import Layout from "../../../components/layout"
import { Helmet } from "react-helmet"
import { Seo } from "../../../components/seo"
import { Typography } from "antd"
import PageContent from "../../../components/page-content"
const { Title } = Typography

const ElementsIndex = (data) => {
  if (!data) return null

  return (
    <Layout>
      <Seo title={"Elements"} />
      <PageContent>
        <Title>Elements</Title>
        {/* <h1>Feed Chart</h1> */}
      </PageContent>
    </Layout>
  )
}

export default ElementsIndex
